import { EyeIcon, PaperAirplaneIcon, PencilIcon, PlusSmallIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button, Card, Col, Grid, Spinner, TwoColTable } from 'components/gsys-ui'
import PhonePreview from 'components/bulletins/PhonePreview'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import NiceModal from '@ebay/nice-modal-react'
import { useBulletins } from 'util/queries'
import moment from 'moment'
import EmptyMsg from 'components/EmptyMsg'
import { useDeleteBulletin } from 'util/mutations'
import clsx from 'clsx'

const Bulletins = () => {
  const [preview, setPreview] = useState(null)
  const { isLoading, isError, error, data } = useBulletins()

  if (isLoading) return <Spinner size={30} cont={true} />
  const previewBull = data.filter((el) => el._id === preview).pop()

  return (
    <div className="flex h-full">
      <div className="relative flex-1">
        <div className="mb-3">
          <Button
            Icon={PlusSmallIcon}
            onClick={() => NiceModal.show('bulletinsAddBulletinModal')}
          >
            Create bulletin
          </Button>
        </div>
        <div className="flex flex-wrap gap-2">
          {
            !data || data.length === 0 ? (
              <EmptyMsg>There are no bulletins to display.</EmptyMsg>
            ) : (
              data.map((el) => <BulletinCard
                data={el}
                setPreview={setPreview}
                active={preview === el._id}
              />)
            )
          }
        </div>
      </div>

      <PhonePreview html={previewBull && previewBull.Content} />
    </div>

  )
}

const BulletinCard = ({ data, setPreview, active }) => {
  return (
    <div className="w-64">
      <Card className={clsx(
        'h-[273px] p-3 flex flex-col',
        active && 'outline outline-green-600 outline-2'
      )}>
        <div className="flex-none font-bold">{data.Name}</div>
        <div className="overflow-y-scroll flex-1 mb-2 leading-tight">{data.Notes}</div>
        <div className="flex-none mb-2 text-sm">
          <TwoColTable
            data={{
              Date: moment(data.DateTime).format('DD/MM/YYYY'),
              Expiry: moment(data.Expiry).format('DD/MM/YYYY'),
              //User: data.User
            }}
          />
        </div>
        <div className="flex flex-none justify-end">
          <Button variant="borderless" rounded small onClick={() => setPreview(data._id)}>
            <EyeIcon className="w-5 h-5" />
          </Button>
          <Button variant="borderless" rounded small onClick={() => NiceModal.show('bulletinsUpdateBulletinModal', { initial: data, afterSubmit: () => setPreview(data._id) })}>
            <PencilIcon className="w-5 h-5" />
          </Button>
          <Button variant="borderless" rounded small onClick={() => NiceModal.show('bulletinsSendBulletinModal', { bulletinId: data.BulletinGuid, afterSubmit: () => setPreview(data._id) })}>
            <PaperAirplaneIcon className="w-5 h-5" />
          </Button>
          <BulletinDeleteButton bulletinId={data.BulletinGuid} />
        </div>
      </Card>
    </div >
  )
}

const BulletinDeleteButton = ({ bulletinId }) => {
  const mutation = useDeleteBulletin(bulletinId)
  const [isLoading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await mutation.mutateAsync()
  }

  return (
    <div className="flex items-center h-full">
      <Button
        variant="rounded"
        small
        onClick={() => NiceModal.show('deleteModal', { name: 'bulletin', handleDelete })}
        loading={isLoading}
        Icon={TrashIcon}
      />
    </div>
  )
}

export default Bulletins