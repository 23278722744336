import { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import NiceModal from '@ebay/nice-modal-react'

import { Grid, Col, Card, Spinner, Button, Badge } from 'components/gsys-ui'
import { useRun, useRunCustomers, useRuns } from 'util/queries'
import EmptyMsg from 'components/EmptyMsg'
import { CalendarDaysIcon, ExclamationCircleIcon, PlusSmallIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'
import RunCustomerMap from 'components/runs/RunCustomerMap'
import { useAddRunSchedule, useDeleteRun, useDeleteRunSchedule, useDeleteTimeFromRun, useRemoveCustomerFromRun, useUpdateRunDays } from 'util/mutations'
import { GlobalContext } from 'context/GlobalContext'
import { PaginatedTable } from 'components/gsys-ui/Table/Table'
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/London')

const Runs = () => {
  const [selectedRun, setSelectedRun] = useState(null)
  const { branch } = useContext(GlobalContext)

  useEffect(() => {
    setSelectedRun(null)
  }, [branch])

  return (
    <Grid gap={7} className="h-full">
      <Col lg={5}>
        <div className="mb-2">
          <Button Icon={PlusSmallIcon} onClick={() => NiceModal.show('runsAddRunModal')}>Add a run</Button>
        </div>
        <RunsList
          selectedRun={selectedRun}
          setSelectedRun={setSelectedRun}
        />
      </Col>
      {
        selectedRun ? (
          <>
            <Col lg={10}>
              <div className="flex flex-col h-full">
                <ScheduleCard
                  selectedRun={selectedRun}
                />
              </div>
            </Col>
            <Col lg={9}>
              <div className="flex flex-col h-full">
                <CustomerCard
                  selectedRun={selectedRun}
                />
              </div>
            </Col>
          </>
        ) : (
          <Col xxl={3}>

          </Col>
        )
      }
    </Grid>
  )
}

const numToDayLetter = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const numToShortDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const dayBoolsToShortDay = (bools) => bools.reduce((acc, cur, ind) => {
  if (cur) acc.push(numToShortDay[ind])
  return acc
}, [])

const runToCombinedSchedule = (run) => {
  return run.Schedules.map((schedule) => {
    return {
      ...schedule,
      Times: run.Departures.reduce((acc, departure) => {
        if (departure.ScheduleId === schedule._id) return [...acc, departure.TimeCode]
        return acc
      }, [])
    }
  })
}

const RunsList = ({ selectedRun, setSelectedRun }) => {
  const { isLoading, isError, error, data } = useRuns()

  if (isLoading) return <Spinner size={30} cont={true} />
  if (!data || data.length === 0) return <EmptyMsg>There are no runs to display.</EmptyMsg>

  if (!selectedRun) {
    setSelectedRun(data[0]._id)
  }

  return (
    <div className="space-y-2">
      {
        data && data.map((run, ind) => {
          const active = run._id === selectedRun

          return (
            <RunCard key={ind} active={active} run={run} setSelectedRun={setSelectedRun} />
          )
        })
      }
    </div>
  )
}

const RunCard = ({ active, run, setSelectedRun }) => {
  const mutation = useDeleteRun()

  const handleDelete = async () => {
    await mutation.mutateAsync(run._id)
  }

  return (
    <Card
      hover={!active}
      onClick={() => setSelectedRun(run._id)}
      className={clsx(
        '!transition-colors select-none',
        active && '!border-green-600 !border-[2px] !shadow-md',
        !active && 'p-[1px]',
      )}
    >
      <div className="p-2">
        <div className="flex justify-between mb-1 w-full">
          <div className="font-bold">{run.Name}</div>
          <Button
            rounded
            tiny
            variant="white"
            onClick={() => NiceModal.show('deleteModal', { handleDelete: handleDelete, name: `run "${run.Name}"` })}
          >
            <TrashIcon className="w-5 h-5" />
          </Button>
        </div>
        <div className="flex justify-between w-full">
          <div>
            {run.Customers.length} customer{run.Customers.length !== 1 && 's'}
          </div>
          <div className="flex space-x-1 text-md">
            {
              run.Schedules
                .reduce((acc, cur) => {
                  for (let i = 0; i < cur.Days.length; i++) {
                    if (cur.Days[i]) acc[i] = true
                  }

                  return acc
                }, new Array(7).fill(false))
                .map((isActive, ind) => (
                  <div
                    key={ind}
                    className={clsx(
                      !isActive && 'text-gray-300'
                    )}
                  >
                    {numToDayLetter[ind]}
                  </div>
                ))
            }
          </div>
        </div>
      </div>
    </Card>
  )
}

const ScheduleCard = ({ selectedRun }) => {
  const { isLoading, isError, error, data } = useRun(selectedRun, { enabled: !!selectedRun })
  const mutation = useAddRunSchedule()
  const deleteMutation = useDeleteRunSchedule()

  const handleDelete = (scheduleId) => {
    return deleteMutation.mutateAsync({ runId: selectedRun, scheduleId })
  }

  if (!selectedRun) return null
  if (isLoading) return null

  return (
    <div className="relative flex-1 w-full">
      <Card className="flex absolute inset-0 flex-col">
        <div className="flex-none">
          <Card.Title bordered Icon={CalendarDaysIcon}>
            <div>SCHEDULES</div>
            <Button
              Icon={PlusSmallIcon}
              onClick={() => mutation.mutate({ runId: selectedRun })}
              loading={mutation.isLoading}
            >
              Add schedule
            </Button>
          </Card.Title>
        </div>
        <div className="overflow-y-scroll flex-1">
          {
            runToCombinedSchedule(data).map((schedule, ind) => (
              <div key={ind} className="p-3 border-b border-gray-300 last:border-b-0">
                <div className="flex justify-between items-center mb-2">
                  <div className="font-bold">Schedule {ind + 1}</div>
                  <Button
                    rounded
                    small
                    variant="borderless"
                    onClick={() => NiceModal.show('deleteModal', { name: 'schedule', handleDelete: () => handleDelete(schedule._id) })}
                  >
                    <TrashIcon className="w-5 h-5" />
                  </Button>
                </div>
                <ScheduleDayButtons
                  runId={selectedRun}
                  schedule={schedule}
                />
                <div className="">Departure times</div>
                <div className="flex items-center space-x-1">
                  {
                    schedule.Times.length === 0 && (
                      <div className="text-sm italic text-gray-500">No times set</div>
                    )
                  }
                  {
                    schedule.Times.sort().map((time, ind) => (
                      <TimeBadge
                        key={ind}
                        runId={selectedRun}
                        scheduleId={schedule._id}
                        time={time}
                      />
                    ))
                  }
                  <Button
                    rounded
                    tiny
                    variant="borderless"
                    Icon={PlusSmallIcon}
                    onClick={() => (
                      NiceModal.show(
                        'runsTimePickerModal',
                        { runId: selectedRun, scheduleId: schedule._id }
                      )
                    )}
                  />
                </div>
              </div>
            ))
          }
          {
            data.Schedules.length === 0 && (
              <EmptyMsg>No schedules have been added.</EmptyMsg>
            )
          }
        </div>
      </Card></div>
  )
}

const TimeBadge = ({ runId, scheduleId, time }) => {
  const [isHover, setHover] = useState(true)
  const mutation = useDeleteTimeFromRun()

  const handleClick = () => {
    mutation.mutate({ runId, scheduleId, time })
  }

  return (
    <Badge
      variant="gray"
      onMouseOver={() => setHover(true)}
    >
      <div className="flex items-center">
        <span>
          {
            //moment(time).format('HHmm')
            time
          }
        </span>
        <div className="cursor-pointer" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="ml-1 w-4 h-4">
            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
          </svg>
        </div>
      </div>
    </Badge >
  )
}

const ScheduleDayButtons = ({ runId, schedule }) => {
  const mutation = useUpdateRunDays()

  const handleUpdateDays = (ind, newValue) => {
    let newDays = [...schedule.Days]
    newDays[ind] = newValue
    mutation.mutate({ runId, scheduleId: schedule._id, days: newDays })
  }

  return (
    <>
      <div className="flex justify-around mb-0.5 w-full">
        {
          schedule.Days.map((isActive, ind) => (
            <Button
              small
              rounded
              variant="white"
              className={clsx(
                isActive && 'outline outline-2 outline-blue-500',
                !isActive && 'outline outline-1 outline-gray-300'
              )}
              onClick={() => handleUpdateDays(ind, !isActive)}
            >
              {numToDayLetter[ind]}
            </Button>
          ))
        }
      </div>
      {
        schedule.Days.every((el) => el) ? (
          <div className="flex justify-end">
            Runs every day
          </div>
        ) : schedule.Days.some((el) => el) ? (
          <div className="flex justify-end">
            Runs every {dayBoolsToShortDay(schedule.Days).join(', ')}
          </div>
        ) : (
          <div className="flex justify-end">
            Not running
          </div>
        )
      }
    </>
  )
}

const CustomerCard = ({ selectedRun }) => {
  const { isLoading, isError, error, data } = useRunCustomers(selectedRun, { enabled: !!selectedRun })

  if (isLoading || !data) return <Spinner size={30} cont={true} />

  return (
    <>
      <Card className="mb-2 flex-0">
        <Card.Title Icon={UsersIcon}>
          <div>CUSTOMERS</div>
          <Button
            Icon={PlusSmallIcon}
            onClick={() => NiceModal.show('runsAddCustomerModal', { runId: selectedRun })}
          >
            Add customer
          </Button>
        </Card.Title>
        <div className="pt-2">
          <PaginatedTable
            thinRows
            columns={[
              {
                header: 'ACCT',
                accessorKey: 'KeyCode',
                cellSize: 1
              },
              {
                header: 'NAME',
                accessorKey: 'Name'
              },
              {
                header: '',
                accessorKey: 'warn',
                cellSize: 1,
                cellPad: false,
                cell: ({ row }) => row.original.Loc ? null : (
                  <div className="flex items-center" title="This customer does not have an assigned location">
                    <ExclamationCircleIcon className="mx-1 w-5 h-5 text-red-500" />
                  </div>
                )
              },
              {
                header: '',
                accessorKey: 'delete',
                cellSize: 20,
                disableSortBy: true,
                cellPad: false,
                cell: ({ row }) => (
                  <CustomerDeleteButton
                    runId={selectedRun}
                    acct={row.original.KeyCode}
                  />
                )
              }
            ]}
            data={data}
            pageSize={5}
            padToPageSize={true}
          />
        </div>
      </Card>
      <Card className="h-full">
        <RunCustomerMap runId={selectedRun} />
      </Card>
    </>
  )
}

const CustomerDeleteButton = ({ runId, acct }) => {
  const mutation = useRemoveCustomerFromRun()
  const [isLoading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await mutation.mutateAsync({ runId, acct })
  }

  return (
    <div className="flex items-center h-full">
      <Button
        variant="rounded"
        tiny
        onClick={() => NiceModal.show('deleteModal', { name: 'customer', handleDelete })}
        loading={isLoading}
        Icon={TrashIcon}
      />
    </div>
  )
}

export default Runs