import NiceModal from '@ebay/nice-modal-react'
import RegPlate from 'components/RegPlate'
import { Button, Card, Col, DateInput, Grid, Input } from 'components/gsys-ui'
import moment from 'moment'
import { useState } from 'react'
import { useUpdateCash } from 'util/mutations'
import { useCash } from 'util/queries'

const Payments = () => {
  const [date, setDate] = useState(new Date())
  const { isLoading, isError, error, data } = useCash(date)

  if (isLoading) return null

  return (
    <Grid gap={7}>
      <Col sm={24}>
        <Card>
          <div className="p-2">
            <div className="w-[200px]">
              <DateInput value={date} onChange={setDate} />
            </div>
          </div>
          <div className="p-2">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">ACCT</th>
                  <th className="text-left">NAME</th>
                  <th className="text-left">DOCUMENT</th>
                  <th className="text-left">AMOUNT</th>
                  <th className="text-left">DRIVER</th>
                  <th className="text-left">METHOD</th>
                  <th className="text-left">CONFIRMED BY</th>
                  <th className="text-left">CONFIRMED DATE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((row) => <CashRow row={row} />)
                }
              </tbody>
            </table>
          </div>
        </Card>
      </Col>
    </Grid>
  )
}

const CashRow = ({ row }) => {
  const [document, setDocument] = useState(row.Document.join(', '))
  const [amount, setAmount] = useState(row.Amount)
  const [confirmedBy, setConfirmedBy] = useState('')
  const [confirmedDate, setConfirmedDate] = useState(Date.now())
  const mutation = useUpdateCash(row._id)

  return (
    <tr>
      <td>{row.Acct}</td>
      <td>{row.Name}</td>
      <td>{row.Document.join(', ')}</td>
      <td>
        {
          row.ConfirmedDate ? (
            `£${parseFloat(row.Amount).toFixed(2)}`
          ) : (
            <Input value={amount} onChange={(e) => setAmount(e.target.value)} />
          )
        }
      </td>
      <td>
        <RegPlate size={0.7}>{row.User}</RegPlate>
      </td>
      <td>{row.Notes}</td>
      <td>
        {
          row.ConfirmedDate ? (
            row.ConfirmedBy
          ) : (
            <Input value={confirmedBy} onChange={(e) => setConfirmedBy(e.target.value)} />
          )
        }
      </td>
      <td>
        {
          row.ConfirmedDate ? (
            moment(row.ConfirmedDate).format('YYYY-MM-DD')
          ) : (
            <DateInput value={confirmedDate} onChange={setConfirmedDate} />
          )
        }
      </td>
      <td>
        {
          !row.ConfirmedDate && (
            <Button
              className="w-full"
              onClick={
                () => NiceModal.show(
                  'confirmModal',
                  {
                    title: 'Confirm payment?',
                    handleConfirm: () => mutation.mutateAsync({ confby: confirmedBy, confdate: moment(confirmedDate).toDate() })
                  }
                )
              }
            >
              Confirm
            </Button>
          )
        }
      </td>
    </tr>
  )
}

export default Payments