import { DatePicker } from 'antd'

import s from './DateInput.module.scss'
import { useField } from 'formik'
import dayjs from 'dayjs'

const DateInput = (props) => {
  const value = !!props.value ? props.value : undefined

  return (
    <div className={s.inputGroup}>
      {
        props.label && (
          <div className={s.label}>{props.label}</div>
        )
      }
      {props.value.toDate()}
      <DatePicker
        className={s.picker}
        popupClassName={s.popup}
        placeholder={props.placeholder ?? ''}
        {...props}
        onChange={(val) => props.onChange(val.toDate())}
        value={value ? dayjs(value) : null}
      />
    </div>
  )
}

export const DateInputFormik = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)

  return (
    <DateInput
      {...props}
      {...field}
      label={label}
      formikError={!!meta.error}
      onChange={helpers.setValue}
    />
  )
}

export default DateInput