import RegPlate from 'components/RegPlate'
import { Col, Grid, Spinner } from 'components/gsys-ui'
import { useActiveDrivers } from 'util/queries'
import EmptyMsg from 'components/EmptyMsg'
import Map from 'components/Map'
import DriverCard from 'components/active/DriverCard'
import { Link } from '@tanstack/react-router'
import VanIcon from 'components/VanIcon'

const Active = () => {
  const { isLoading, isError, error, data } = useActiveDrivers()

  if (isLoading) return <Spinner size={30} cont={true} />
  if (!data || data.length === 0) return <EmptyMsg>There are no active runs to display.</EmptyMsg>

  const points = data.map((el) => el.Location[0])

  return (
    <Grid gap={7} className="h-full">
      <Col sm={5}>
        <div className="space-y-2">
          {
            data.map((driver, ind) => (
              <div>
                <Link to={`/active/${driver.ActiveRuns[0].RunId}`}>
                  <DriverCard ind={ind} data={driver} />
                </Link>
              </div>
            ))
          }
        </div>
      </Col>
      <Col sm={19}>
        <Map points={points}>
          {
            data.map((point, ind) => {
              const firstPendingDrop = point.ActiveRuns.findIndex((el, ind) => {
                if (ind === 0 && el.Status === 'Pending') return true
                if (el.Status == 'Pending' && point.ActiveRuns[ind - 1].Status === 'Complete') return true
                return false
              })

              const firstPendingIsLast = firstPendingDrop === point.ActiveRuns.length - 1

              return (
                < VanIcon
                  key={ind}
                  lat={point.Location[0].lat}
                  lng={point.Location[0].lon}
                  reg={point.User}
                  returnTime={firstPendingIsLast ? point.ActiveRuns[point.ActiveRuns.length - 1].Eta : null}
                />
              )
            })
          }
        </Map>
      </Col>
    </Grid>
  )
}

export default Active